
.q-scrollarea {
	height: 100%;
	position: static;

	&::v-deep {
		.q-scrollarea__content {
			width: 100%;
			position: static;
		}
		.q-scrollarea__container {
			position: static;
		}
	}
}
